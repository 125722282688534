<template>
  <section>
    <section class="search-container">
      <a-form
        layout="inline"
        :form="serviveForm"
        class="m-t-14"
        @submit="submitForm"
      >
        <a-form-item label="仓库名称">
          <a-input
            class="search-input"
            autocomplete="off"
            v-decorator="['name']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item label="仓库管理员">
          <a-input
            class="search-input"
            autocomplete="off"
            v-decorator="['manager']"
            placeholder="请输入"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit"
            ><a-icon type="search" />查询</a-button
          >
          <a-button @click="reset"><a-icon type="reload" />重置</a-button>
        </a-form-item>
      </a-form>
      <a-row class="right-action-panel m-t-14">
        <a-button type="primary" class="item" @click="addWork"
          >添加仓库</a-button
        >
      </a-row>
    </section>

    <!-- <a-row type="flex" :gutter="20" class="list-card-container">
      <a-col
        :span="6"
        v-for="(item, key) in records"
        :key="key"
        class="m-t-16 list-item-wrap"
      >
        <a-row class="list-card-bg">
          <a-row class="list-wrap">
            <a-col>
              <a-row class="list-item">
                {{ item.name }}
              </a-row>
              <a-row class="list-item"> 位置:{{ item.address }} </a-row>

              <a-row class="list-item"> 管理员:{{ item.manager }} </a-row>

              <a-row class="list-btn-group-position">
                <a-button size="small" @click="detailsWarehouse(item, '1')"
                  >查看</a-button
                >
                <a-dropdown>
                  <a-button size="small"
                    >更多<a-icon type="caret-down"
                  /></a-button>
                  <a-menu slot="overlay">
                    <a-menu-item key="1">
                      <span @click="detailsWarehouse(item, '2')">编辑</span>
                    </a-menu-item>
                    <a-menu-item key="2">
                      <a-popconfirm
                        @confirm="deletewarehouse(item)"
                        title="确定删除?"
                        >删除</a-popconfirm
                      >
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </a-row>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row> -->
    <!-- <section class="pagination-container paginatioPositon">
      <a-pagination
        size="default"
        v-model="current"
        :total="total"
        :page-size="pageSize"
        @change="onChange"
      />
    </section> -->

    <section class="list-table-container list-table">
      <a-table
        :bordered="false"
        :data-source="list.data"
        size="small"
        class="m-t-18"
        :columns="list.columns"
        @change="changePageNum"
        :pagination="{
          showQuickJumper: false,
          current: list.pageNum,
          total: list.total,
        }"
      >
        <template slot="operation" slot-scope="list, text">
          <a @click="detailsWarehouse(text, '1')" style="color: #529aff"
            >查看</a
          >
            <a-divider style="color:#222" type="vertical" />
           <a @click="detailsWarehouse(text, '2')">编辑</a>
             <a-divider style="color:#222" type="vertical" />
           <a @click="deletewarehouse(text)">删除</a>

          <!-- <a style="color:#529aff">查看</a> -->
          <!-- /mms/inout/warehouseIn/warehouseOutDetail
          <a-divider style="color:#222" type="vertical" />
          <a style="color:#529aff">删除</a> -->
        </template>
      </a-table>
    </section>
    <a-modal
      centered
      width="660px"
      class="modal-container"
      :title="typeModal.title"
      v-model="typeModal.visible"
      :destroyOnClose="true"
      :footer="false"
    >
      <a-form :form="forms" @submit="submitForms">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="仓库名称"
        >
          <a-input
            placeholder="请输入"
            autocomplete="off"
            :disabled="disabledStatus"
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '仓库名称不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>

        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="仓库位置"
        >
          <a-input
            placeholder="请输入"
            autocomplete="off"
            :disabled="disabledStatus"
            v-decorator="[
              'address',
              { rules: [{ required: true, message: '仓库位置不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="管理员"
        >
          <a-input
            placeholder="请输入"
            autocomplete="off"
            :disabled="disabledStatus"
            v-decorator="[
              'manager',
              { rules: [{ required: true, message: '管理员不能为空' }] },
            ]"
            class="form-input"
          ></a-input>
        </a-form-item>

        <a-form-item class="form-btn-group">
          <a-button v-if="checkType != '1'" class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="typeModal.visible = false"
            >取消</a-button
          >
        </a-form-item>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
export default {
  data() {
    return {
      warehouseId: null,

      checkType: "",
      disabledStatus: false,
      records: [], //仓库列表
      list: {
        total: 0,
        data: [],
        pageNum: 1,
        columns: [
          { title: "序号", dataIndex: "key", align: "center" },
          { title: "仓库名称", dataIndex: "name", align: "center" },
          { title: "位置", dataIndex: "address", align: "center" },
          { title: "管理员", dataIndex: "manager", align: "center" },
          {
            title: "操作",
            dataIndex: "operation",
            align: "center",
            scopedSlots: { customRender: "operation" },
          },
        ],
      },
      serviveForm: this.$form.createForm(this),
      forms: this.$form.createForm(this),
      typeModal: {
        visible: false,
        title: "添加仓库",
      },
    };
  },
  created() {
    // 仓库列表
    this.warehouseList();
  },
  methods: {
    // 查询仓库
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.serviveForm.getFieldsValue();

      (this.manager = formVal.manager),
        (this.name = formVal.name),
        this.warehouseList();
    },
    // 重置
    reset() {
      this.manager = "";
      this.name = "";
      this.list.current = "1";

      // 重置
      this.serviveForm.resetFields();
      this.warehouseList();
    },
    // 新增仓库
    submitForms(e) {
      e && e.preventDefault();
      this.forms.validateFields((err, values) => {
        if (!err) {
          let params = {};
          if (this.warehouseId) {
            params.manager = values.manager;
            params.name = values.name;
            params.address = values.address;
            params.id = this.warehouseId;
          } else {
            params.manager = values.manager;
            params.name = values.name;
            params.address = values.address;
          }
          let fun = this.warehouseId
            ? this.$api.editWarehouse(params)
            : this.$api.addwarehouse(params);
          fun.then((res) => {
            if (res.code == "200") {
              
              this.typeModal.visible = false;
              this.warehouseList();
              this.warehouseId = null;
            }
          });
          // ? this.$api.editWarehouse(params).then(res => {
          //     if (res.code == "200") {
          //       this.$message.success("修改成功!");
          //       this.typeModal.visible = false;
          //       this.warehouseList();
          //       this.warehouseId=null
          //     }
          //   })
          // :this.$api.addwarehouse(params).then(res => {
          //     if (res.code == "200") {
          //       this.$message.success("新增成功!");
          //       this.typeModal.visible = false;
          //       this.warehouseList();
          //       this.warehouseId=null
          //     }
          //   });
        }
      });
    },
    addWork() {
      this.typeModal.visible = true;
      this.typeModal.title = "新增";
    },
    // 仓库详情
    detailsWarehouse(list, type) {
      this.$api.detailsWarehouse(list.id).then((res) => {
        if (res.code == "200") {
          this.checkType = type;
          this.disabledStatus = type == "1" ? true : false;
          this.typeModal.title = type == "1" ? "查看" : "修改";
          this.warehouseId = list.id; //仓库id
          this.typeModal.visible = true;
          let obj = res.data;
          setTimeout(() => {
            this.forms.setFieldsValue({
              name: obj.name,
              address: obj.address,
              manager: obj.manager,
            });
          }, 0);
        }
      });
    },
    // 删除仓库
    deletewarehouse(list) {
      this.$api.deletewarehouse(list.id).then((res) => {
        if (res.code == "200") {
         
          this.warehouseList();
        }
      });
    },
    // 仓库详情
    // 列表
    warehouseList() {
      let params = {
        manager: this.manager,
        name: this.name,
        current: this.list.pageNum,
      };
      this.$api.warehouseList(params).then((res) => {
        if (res.code == "200") {
          res.data.records &&
            res.data.records.forEach((item, index) => {
              item.key = index + 1;
            });
          this.list.data = res.data.records;

          this.list.total = res.data.total;
          // console.log(this.list.data, "this.list.data");
        }
      });
    },
    // 分页
    changePageNum(p) {
      this.list.pageNum = p.current;
      this.warehouseList();
    },
  },
};
</script>
<style lang="scss" scoped>
.list-wrap {
  padding: 16px 10px 24px 10px;
}
.list-btn-group-position {
  bottom: -12px !important;
}
</style>