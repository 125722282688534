var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('section',{staticClass:"search-container"},[_c('a-form',{staticClass:"m-t-14",attrs:{"layout":"inline","form":_vm.serviveForm},on:{"submit":_vm.submitForm}},[_c('a-form-item',{attrs:{"label":"仓库名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name']),expression:"['name']"}],staticClass:"search-input",attrs:{"autocomplete":"off","placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"仓库管理员"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['manager']),expression:"['manager']"}],staticClass:"search-input",attrs:{"autocomplete":"off","placeholder":"请输入"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"m-r-8",attrs:{"type":"primary","html-type":"submit"}},[_c('a-icon',{attrs:{"type":"search"}}),_vm._v("查询")],1),_c('a-button',{on:{"click":_vm.reset}},[_c('a-icon',{attrs:{"type":"reload"}}),_vm._v("重置")],1)],1)],1),_c('a-row',{staticClass:"right-action-panel m-t-14"},[_c('a-button',{staticClass:"item",attrs:{"type":"primary"},on:{"click":_vm.addWork}},[_vm._v("添加仓库")])],1)],1),_c('section',{staticClass:"list-table-container list-table"},[_c('a-table',{staticClass:"m-t-18",attrs:{"bordered":false,"data-source":_vm.list.data,"size":"small","columns":_vm.list.columns,"pagination":{
        showQuickJumper: false,
        current: _vm.list.pageNum,
        total: _vm.list.total,
      }},on:{"change":_vm.changePageNum},scopedSlots:_vm._u([{key:"operation",fn:function(list, text){return [_c('a',{staticStyle:{"color":"#529aff"},on:{"click":function($event){return _vm.detailsWarehouse(text, '1')}}},[_vm._v("查看")]),_c('a-divider',{staticStyle:{"color":"#222"},attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.detailsWarehouse(text, '2')}}},[_vm._v("编辑")]),_c('a-divider',{staticStyle:{"color":"#222"},attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.deletewarehouse(text)}}},[_vm._v("删除")])]}}])})],1),_c('a-modal',{staticClass:"modal-container",attrs:{"centered":"","width":"660px","title":_vm.typeModal.title,"destroyOnClose":true,"footer":false},model:{value:(_vm.typeModal.visible),callback:function ($$v) {_vm.$set(_vm.typeModal, "visible", $$v)},expression:"typeModal.visible"}},[_c('a-form',{attrs:{"form":_vm.forms},on:{"submit":_vm.submitForms}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"label":"仓库名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '仓库名称不能为空' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '仓库名称不能为空' }] },\n          ]"}],staticClass:"form-input",attrs:{"placeholder":"请输入","autocomplete":"off","disabled":_vm.disabledStatus}})],1),_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"label":"仓库位置"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'address',
            { rules: [{ required: true, message: '仓库位置不能为空' }] },
          ]),expression:"[\n            'address',\n            { rules: [{ required: true, message: '仓库位置不能为空' }] },\n          ]"}],staticClass:"form-input",attrs:{"placeholder":"请输入","autocomplete":"off","disabled":_vm.disabledStatus}})],1),_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"label":"管理员"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'manager',
            { rules: [{ required: true, message: '管理员不能为空' }] },
          ]),expression:"[\n            'manager',\n            { rules: [{ required: true, message: '管理员不能为空' }] },\n          ]"}],staticClass:"form-input",attrs:{"placeholder":"请输入","autocomplete":"off","disabled":_vm.disabledStatus}})],1),_c('a-form-item',{staticClass:"form-btn-group"},[(_vm.checkType != '1')?_c('a-button',{staticClass:"btn-save",attrs:{"html-type":"submit"}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v("保存")],1):_vm._e(),_c('a-button',{staticClass:"btn-cancel",on:{"click":function($event){_vm.typeModal.visible = false}}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }